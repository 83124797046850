export const Overons = {
    hero: {
        picture: '',
        title: 'Over ons',
        text: 'Wie is Super Drive?',
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
                    <p>Leuk dat je even komt kijken op de website van onze rijschool! Op onze site vind je alles wat je nodig hebt om je rijbewijs B op een vlotte en gezellige manier te halen. Uiteraard leiden wij op voor het nieuwe rijexamen.</p><br /><br />
                    <p>Wij bieden u de mogelijkheid op uw eigen tempo uw rijbewijs te halen. Een rijopleiding is een kostbare zaak, daarom is het van groot belang om een rijschool uit te kiezen welke voldoet aan uw wensen.</p><br /><br />
                    <p>Denk aan:</p><br />

                      <li>Prettig & persoonlijke benadering van je rij-instructeur</li>
                      <li>Betrouwbaar</li>
                      <li>Kwaliteit</li>
                      <li>Professionaliteit</li>
                      <li>Klantvriendelijk</li>
                      <li>Lage lesprijzen en een goede service</li>
                      <li>Rust en veel geduld van je rij-instructeur</li>
                      <li>Inlevingsvermogen</li>
                      <li>Geen wachtlijsten</li>
                      <li>Up to date</li>
                      <li>Een rijopleiding waar veel mogelijk is en alles bespreekbaar is</li>
                      <li>Faalangst of trauma is geen probleem</li>
                      <li>De lessen worden in een ontspannen sfeer met de leerling gepland</li>
                      <li>Rijles in een nieuwe en rookvrije lesauto!</li>
  
                    <br /><br />
                    <p>Rijschool Super Drive vindt het belangrijk dat je eerst de auto een beetje leert kennen, zodat je met een vertrouwd gevoel achter het stuur gaat zitten. Afhankelijk van de beginsituatie worden stapsgewijs alle vaardigheden van het autorijden bijgebracht op een plezierige manier. Je leert in korte tijd vlot, veilig én zelfstandig autorijden. Dit wordt ook van je verwacht tijdens je rijexamen.</p><br /><br />
                    <p>Of u nu kiest voor een uur, twee of meer uren per week te gaan lessen, of het volgen van een spoedcursus, de keuze is aan jou, alles is mogelijk in overleg!</p><br /><br />
                    <p>Met een goede inzet en enthousiaste gemotiveerde rij-instructeur die u in een zo kort mogelijke tijd naar het examen begeleidt, en natuurlijk een goede motivatie van jou zelf, kun je ervan verzekerd zijn het rijbewijs binnen niet al te lange tijd in bezit te hebben, dus maak een Superstart bij Super Drive!</p>                    
                    `,
                    // button: {
                    //     text: 'Lees meer',
                    //     link: '/overons'
                    // }
                },
            ],
        },
    ],
}