<template>
    <footer class="bg-gray-200 mt-16">
        <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div class="md:flex md:justify-between">
                <div class="mb-4 md:mb-0">
                    <a href="/" class="flex items-center">
                        <img src="./../assets/logo.svg" class="h-8 me-3" alt="FlowBite Logo" />
                    </a>
                    <ul v-if="footer.getFooter" class="text-gray-600 font-medium mt-4 mx-3">
                        <li v-for="item in footer.getFooter" :key="item.id" class="mb-4">
                            <a :href="item.link" class="hover:underline hover:text-primary">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>
                <div class="mx-3">
                    <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase h-8">Contact</h2>
                    <ul class="text-gray-600 font-medium">
                        <li class="mb-4">
                            <a href="https://www.facebook.com/p/Superdrive-100057566075178/" class="hover:underline hover:text-primary">FaceBook</a>
                        </li>
                        <li class="mb-4">
                            <a href="mailto:jimmyramjan@home.nl" class="hover:underline hover:text-primary">jimmyramjan@home.nl</a>
                        </li>
                        <li class="mb-4">
                            <a href="https://wa.me/31612646163" class="hover:underline hover:text-primary">WhatsApp</a>
                        </li>
                        <li class="mb-4">
                            <a href="tel:31612646163" class="hover:underline hover:text-primary">+31 6 12646163</a>
                        </li>
                    </ul>
                </div>
                <div class="mx-3">
                    <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase h-8">SuperDrive</h2>
                    <ul class="text-gray-600 font-medium">
                        <li class="mb-4">
                            <p>KVK: 57072922</p>
                        </li>
                        <li class="mb-4">
                            <p>Adress: Twellosebeek 4, 8033BW Zwolle</p>
                        </li>
                        <li class="mb-4">
                            <p>rijschoolnummer: 1664X5</p>
                        </li>
                        <li class="mb-4">
                            <p>IBAN: NL31 RABO 0180 5000 07 (Superdrive)</p>
                        </li>
                    </ul>
                </div>
                <div class="mx-3">
                    <h2 class="mb-4 me-3 text-sm font-semibold text-gray-900 uppercase h-8">Menu items</h2>
                    <ul v-if="menu.getMenu" class="text-gray-600 font-medium">
                        <li v-for="item in menu.getMenu" :key="item.id" class="mb-4">
                            <a :href="item.link" class="hover:underline hover:text-primary">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr class="my-6 border-gray-700 sm:mx-auto lg:my-8" />
            <div class="grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-0">
                <span class="text-sm text-center text-gray-600 md:text-left">
                    © {{ currentYear }} superdrive. All Rights Reserved.
                </span>
                <span class="text-sm text-gray-600 text-center">
                    Website created by <a href="https://www.sparkycoding.com" class="underline hover:text-primary">SparkyCoding</a>
                </span>
                <div class="flex mt-4 justify-center md:justify-end md:mt-0">
                    <a href="https://www.facebook.com/p/Superdrive-100057566075178/"
                        class="text-gray-600 hover:text-primary">
                        <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                            viewBox="0 0 8 19">
                            <path fill-rule="evenodd"
                                d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                                clip-rule="evenodd" />
                        </svg>
                        <span class="sr-only">Facebook page</span>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { useMenuStore } from '@/stores/menu.js';
import { useFooterStore } from '@/stores/footer.js';

const currentYear = new Date().getFullYear();
const menu = useMenuStore();
const footer = useFooterStore();
</script>