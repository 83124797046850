<template>
    <section class="bg-center bg-no-repeat bg-[url('http://localhost:8080/background.jpg')] bg-gray-700 bg-blend-multiply">
        <div class="px-4 mx-auto max-w-screen-xl text-center py-16 lg:py-46">
            <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">"Always drive carefully & safely"</h1>
            <p class="text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">Jimmy</p>
        </div>
    </section>
</template>

<script setup>
</script>