export const Contact =  {
    hero: {
        picture: '',
        title: 'Contact',
        text: 'Maak kennis met Super Drive',
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
                    <a href="https://www.facebook.com/p/Superdrive-100057566075178/" class="hover:underline hover:text-primary">FaceBook</a>
                    <br /><br />
                    <a href="mailto:jimmyramjan@home.nl" class="hover:underline hover:text-primary">jimmyramjan@home.nl</a>
                    <br /><br />
                    <a href="https://wa.me/31612646163" class="hover:underline hover:text-primary">WhatsApp</a>
                    `,
                }
            ],
        },
    ],
}