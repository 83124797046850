export const Home = {
    hero: {
        picture: '',
        title: 'Rijschool Superdrive',
        text: 'De rijschool in de regio Zwolle, Kampen, Wezep, Hasselt en Hattem',
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 2,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: 'Wie is Super Drive?',
                    subtitle: null,
                    text: `Leuk dat je even komt kijken op de website van onze rijschool! 
                    Op onze site vind je alles wat je nodig hebt om je rijbewijs B op een vlotte en gezellige manier te halen. Uiteraard leiden wij op voor het nieuwe 
                    rijexamen. Wij bieden u de mogelijkheid op uw eigen tempo uw rijbewijs te halen. Een rijopleiding is een kostbare zaak,
                    daarom is het van groot belang om een rijschool uit te kiezen welke voldoet aan uw wensen.`,
                    button: {
                        text: 'Lees meer',
                        link: '/overons'
                    }
                },
                {
                    id: 2,
                    type: 'ImageBlock',
                    src: '/lesfoto.png',
                    alt: 'Rijles'
                }
            ],
        },
        {
            id: 1,
            posistion: 'center',
            title: "Lespakketten",
            subtitle: null,
            button: {
                text: 'Bekijk alle pakketten',
                link: '/tarieven'
            },
            cols: 4,
            content: [
                {
                    id: 1,
                    type: 'ColumnBlock',
                    title: 'Pakket A',
                    subtitle: 'Rijles',
                    price: 1350,
                    points: [
                        '20 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
                {
                    id: 2,
                    type: 'ColumnBlock',
                    title: 'Pakket B',
                    subtitle: 'Rijles',
                    price: 1625,
                    points: [
                        '25 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
                {
                    id: 3,
                    type: 'ColumnBlock',
                    title: 'Pakket C',
                    subtitle: 'Rijles',
                    price: 1900,
                    points: [
                        '30 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
                {
                    id: 4,
                    type: 'ColumnBlock',
                    title: 'Pakket D',
                    subtitle: 'Rijles',
                    price: 2175,
                    points: [
                        '35 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
            ],
        },
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 2,
            content: [
                {
                    id: 2,
                    type: 'ImageBlock',
                    src: '/driver.jpg',
                    alt: 'Opleiding'
                },
                {
                    id: 1,
                    type: 'TextBlock',
                    title: 'Opleiding',
                    subtitle: "Rijlessen bij Super Drive",
                    text: `Bij een investering ter grootte van een rijbewijs wil je graag zeker weten dat
                     je waar krijgt voor je geld. Bij Super Drive ben je verzekerd van een goede instructeur 
                     en een ontspannen sfeer. Er is ruimte om je op je eigen tempo te ontwikkelen. De instructeur zal op 
                     afgesproken momenten een evaluatie doen zodat je weet waar je aan toe bent en waar je aan moet werken.`,
                    button: {
                        text: 'Lees meer',
                        link: '/opleidingen'
                    }
                }
            ],
        },
    ],
}