<template>
  <div class="app">
    <header>
      <NavbarBlock />
    </header>
    <router-view />
    <FooterBlock />
  </div>
</template>

<script setup>
import NavbarBlock from './components/NavbarBlock.vue'
import FooterBlock from './components/FooterBlock.vue'
import { useMenuStore } from './stores/menu';
import { usePagesStore } from './stores/pages';
import { useFooterStore } from './stores/footer';
import { onMounted } from 'vue';

const menu = useMenuStore();
const pages = usePagesStore();
const footer = useFooterStore();

onMounted(() => {
  menu.fetchMenu();
  pages.fetchPages();
  footer.fetchFooter();
})
</script>