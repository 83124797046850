import { defineStore } from "pinia";
import { footer } from '@/data.js'

export const useFooterStore = defineStore({
    id: 'footer',
    state: () => ({
        footer: [],
        fetched: false
    }),
    getters: {
        getFooter() {
            return this.fetched ? this.footer : []
        },
    },
    actions: {
        fetchFooter() {
            if (!this.fetched) {
                this.footer = footer;
                this.fetched = true;
            }
        }
    }
})