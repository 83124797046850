export const Proefles =  {
    hero: {
        picture: '',
        title: 'Proefles',
        text: 'Plan een proefles in bij Super Drive',
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'FormBlock',
                }
            ],
        },
    ],
}