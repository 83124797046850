import { defineStore } from "pinia";
import { pages } from '@/data.js'

export const usePagesStore = defineStore({
    id: 'pages',
    state: () => ({
        pages: [],
        fetched: false
    }),
    getters: {
        getPages() {
            return this.fetched ? this.pages : []
        },
    },
    actions: {
        fetchPages() {
            if (!this.fetched) {
                this.pages = pages;
                this.fetched = true;
            }
        }
    }
})