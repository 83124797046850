import { defineStore } from "pinia";
import { menu } from '@/data.js'

export const useMenuStore = defineStore({
    id: 'menu',
    state: () => ({
        menu: [],
        active: 'home',
        fetched: false
    }),
    getters: {
        getMenu() {
            return this.fetched ? this.menu : []
        },
        getActivePage() {
            return this.active
        }
    },
    actions: {
        fetchMenu() {
            if (!this.fetched) {
                this.menu = menu;
                this.fetched = true;
            }
        },
        setActivePage(item) {
            this.active = item;
        }
    }
})