export const Voorwaarden = {
    hero: {
        picture: '',
        title: 'Algemeene voorwaarden',
        text: null,
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
                    <p class="font-semibold">Artikel 1. Algemeen</p>
                    <p>Deze algemene voorwaarden geven de algemene bepalingen weer voor het verzorgen van rijopleiding categorie B, en de daarbij behorende acties van de rijschool en de CBR/BNOR praktijkexamens. Zij zijn van toepassing op de in de lesovereenkomst vermelde rijschool, hierna aan te duiden als 'rijschool'.</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 2. Rijopleiding</p>
                    <p>Rijlessen wordt gegeven door bevoegde instructeur(s)die voldoen aan de voorschriften van de Wet Rijonderricht Motorrijtuigen (WRM). Met een tijdsduur van minimaal 60 minuten. Voor leerlingen die bevoegd zijn om te rijden van 16,5 jaar en ouder. De cursist krijgt zoveel mogelijk les van dezelfde instructeur De cursist die via de rijschool het examen heeft aangevraagd, heeft beschikking over hetzelfde les(motor)rijtuig als waarin hij/ zij de rijinstructie heeft gekregen. Bij noodzaak wordt er een vervangend motorrijtuig van hetzelfde of gelijkwaardige type geregeld.</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 3. Verplichtingen rijschool</p>
                    <p>De rijschool zorgt ervoor dat:</p>
                    <ol class="list-decimal ml-8">
                      <li>de leerling een passende rijopleiding wordt aangeboden;</li>
                      <li>de leerling zo goed als mogelijk tot het CBR/BNOR praktijkexamen wordt opgeleid;</li>
                      <li>wanneer overeenstemming is bereikt over het aanvragen van een praktijkexamen deze binnen 14 dagen bij het CBR wordt ingediend;</li>
                      <li>de datum zo spoedig mogelijk aan de cursist wordt medegedeeld.</li>
                    </ol>
                    <br/><br/>
                    <p class="font-semibold">Artikel 4. Verplichtingen leerling</p>
                    <p>De leerling dient:</p>
                    <ol class="list-decimal ml-8">
                      <li>zich te houden aan de afgesproken datum, tijd en plaats voor de rijles (door de instructeur wordt een wachttijd van maximaal 15 minuten in acht genomen vanaf aanvang rijles)</li>
                      <li>bij het niet nakomen van de afgesproken rijles, zonder tijdige afzegging, de volledige lesprijs te voldoen;</li>
                      <li>bij verhindering van de afgesproken rijles om dringende redenen deze tijdig op te zeggen</li>
                      <li>De leerling mag een rijles afzeggen of verschuiven. Echter wel 48 uur voor aanvang van de rijles aan rijschool kenbaar te maken, anders heeft de rijschool het recht om de volledige lesprijs in rekening te brengen in verband met de gereserveerde tijd.</li>
                      <li>alle aanwijzingen van de rijinstructeur te volgen;</li>
                      <li>ervoor te zorgen dat hij of zij over alle benodigde stukken beschikt voor de tussentijdse toets en CBR/BNOR praktijkexamen;</li>
                      <li>bij niet nakoming van punt 4.6 voor betaling van de tussentijdse toets of CBR/BNOR praktijkexamen aan de rijschool zorg te dragen.</li>
                    </ol>
                    <br/><br/>
                    <p class="font-semibold">Artikel 5. Betaling</p>
                    <p>De leerling is verplicht de verschuldigde bedragen voor lespakketten (10 of meer lessen) bij vooruitbetaling te voldoen aan de rijschool,de betaling gaat per overmaking van bank of giro aan de rijschool of contant.</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 6. Praktijk examen/ Tussentijdse Toets (TTT)</p>
                    <p>Mocht het praktijkexamen/TTT uitgesteld worden door het CBR wegens weersomstandigheden, bijvoorbeeld door ijzel of sneeuw, dan hoeft de leerling het nieuwe examen/TTT niet zelf te betalen. De Rijschool heeft dan wel het recht om aan de leerling de kosten van één rijles te berekenen.</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 7 Theorie</p>
                    <p>De leerling is zelf verantwoordelijk voor het tijdig behalen van het theoriecertificaat. Zonder geldig theoriecertificaat kan er geen Tussentijdse Toets of rij(her)examen worden gereserveerd of afgenomen.</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 8. Beëindiging rijopleiding/losse lessen/pakketten</p>
                    <p>De leerling kan, in geval van een lesovereenkomst bestaande uit losse lessen, deze opzeggen, met inachtneming van punt 4.3;</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 9. Aansprakelijkheid</p>
                    <p>De cursist is niet aansprakelijk voor schaden van tijdens de proefles, rijles en het TTT/praktijkexamen aan derden als gevolg van botsing, aan- of over rijding.</p>
                    <br/><br/>
                    <p class="font-semibold">Artikel 10. Klachtenregeling/Geschillen</p>
                    <p>Klachten dienen ten alle tijden schriftelijk te worden doorgegeven aan de rijschool. Geschillen ontstaan tussen leerling en rijschool zullen in eerste instantie worden voorgelegd aan de rijschool welke zal bemiddelen in het geschil.</p>
                    `,
                },
            ],
        },
    ],
}