export const NotFound =  {
  hero: {
      picture: '',
      title: '404',
      text: 'Pagina niet gevonden',
      button: {
          text: null,
          link: null
      }
  },
  blocks: [
      {
          id: 1,
          posistion: 'left',
          title: null,
          subtitle: null,
          button: null,
          cols: 1,
          content: [
              {
                  id: 1,
                  type: 'TextBlock',
                  title: null,
                  subtitle: null,
                  text: `<b>Deze pagina is niet gevonden</b> <br> <br> <a class="button" href="/">homepagina</a>`,
              }
          ],
      },
  ],
}