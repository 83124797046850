export const Opleiding = {
    hero: {
        picture: '',
        title: 'Opleiding',
        text: 'Rijlessen bij Super Drive',
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
                    <p>Bij een investering ter grootte van een rijbewijs wil je graag zeker weten dat je waar krijgt voor 
                    je geld. Bij Super Drive ben je verzekerd van een goede instructeur en een ontspannen sfeer. Er is ruimte 
                    om je op je eigen tempo te ontwikkelen. De instructeur zal op afgesproken momenten een evaluatie doen zodat 
                    je weet waar je aan toe bent en waar je aan moet werken.</p><br/>
                    <b class="font-semibold">Rijlessen voor mensen met faalangst</b><br/>
                    <p>Heb je goed geleerd voor dat examen? Je weet dat je het allemaal weet, maar zak je toch weer vanwege die zenuwen? Super Drive geeft speciale begeleiding bij faalangst leerlingen.</p><br/>
                    <b class="font-semibold">Rijlessen voor 16.5+</b><br/>
                    <p>Vanaf 16.5 jaar mag je al beginnen met rijlessen en het halen van je theorie. Vanaf je 17e mag je dan al op voor je praktijkexamen. Ook bij super drive kunnen je beginnen van af 16 en half jaar oud met lessen</p>
                    <p>Voor meer informatie zie <a href="https://www.2todrive.nl/nl" class="underline text-blue-500">2ToDrive</a>.</p>
                    `,
                },
            ],
        },
        {
            id: 1,
            posistion: 'center',
            title: null,
            subtitle: null,
            button: {
                text: 'Bekijk alle pakketten',
                link: '/tarieven'
            },
            cols: 4,
            content: [
                {
                    id: 1,
                    type: 'ColumnBlock',
                    title: 'Pakket A',
                    subtitle: 'Rijles',
                    price: 1350,
                    points: [
                        '20 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
                {
                    id: 2,
                    type: 'ColumnBlock',
                    title: 'Pakket B',
                    subtitle: 'Rijles',
                    price: 1625,
                    points: [
                        '25 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
                {
                    id: 3,
                    type: 'ColumnBlock',
                    title: 'Pakket C',
                    subtitle: 'Rijles',
                    price: 1900,
                    points: [
                        '30 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
                {
                    id: 4,
                    type: 'ColumnBlock',
                    title: 'Pakket D',
                    subtitle: 'Rijles',
                    price: 2175,
                    points: [
                        '35 lessen',
                        'Examen',
                    ],
                    button: {
                        text: null,
                        link: null,
                    },
                },
            ],
        },
    ],
}