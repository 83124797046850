export const Gezondheidsverklaring = {
    hero: {
        picture: '',
        title: 'Gezondheidsverklaring',
        text: null,
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
<p>Vanaf 1 november 2017 moet elke examenkandidaat zelf een gezondheidsverklaring indienen bij het CBR. Zonder deze verklaring kan het examen niet aangevraagd worden. Vul je gezondheidsverklaring al aan het begin van je rijopleiding in, zeker als je twijfels hebt over je gezondheid. Op die manier voorkom je dat je moet wachten voor je op examen kunt!</p>
<br/><br/>
<p class="font-semibold">Gezondheidsverklaring voor rijexamen zelf invullen</p>
<p>Eigen Verklaring wordt Gezondheidsverklaring. Voor het inplannen van een praktijkexamen is het noodzakelijk dat de kandidaat medisch goedgekeurd is door het CBR. Vanaf 1 november 2017 is het voor rijscholen niet langer mogelijk om een Eigen verklaring in te dienen voor de kandidaten bij het CBR. Dit heeft het CBR besloten, omdat iedereen zélf verantwoordelijk is voor zijn gezondheidsgegevens. Door zelf de Gezondheidsverklaring CBR in te vullen, denkt een kandidaat bewust na over de vragen over zijn gezondheid. Bovendien zijn deze gegevens persoonlijk en zeer vertrouwelijk.</p>
<br/><br/>
<p class="font-semibold">Waarom een medische verklaring?</p>
<p>Als bestuurder ben je verantwoordelijk voor je eigen veiligheid. En voor die van anderen. Om te mogen rijden moet je daarom volgens de wet ‘rijgeschikt’ zijn. Dat wil zeggen: geestelijk en lichamelijk in staat om te rijden. Heb je bijvoorbeeld gezondheidsproblemen of een beperking? Of gebruik je medicijnen die invloed hebben op hoe je rijdt? Dan mag je niet altijd de weg op. Om je rijgeschiktheid te kunnen beoordelen, stelt het CBR je een aantal vragen over je gezondheid. Deze vragen staan op het formulier Eigen verklaring (Gezondheidsverklaring). Je koopt een Eigen verklaring op Mijn CBR. Zonder deze verklaring kan het praktijkexamen niet aangevraagd worden. Om geen vertraging in het reserveringsproces op te lopen, is het belangrijk dat de gezondheidsverklaring aan het begin van je rijopleiding wordt ingediend! De kosten van deze gezondheidsverklaring zijn in 2024 € 44,50.</p>
<br/><br/>
<p class="font-semibold">Voor welke rijbewijzen is dit van toepassing?</p>
<p>Bij de bromfiets en brommobiel (AM-rijbewijs) is het invullen van de gezondheidsverklaring niet nodig. Voor het auto-, motor-, aanhanger achter de auto- en het trekker rijbewijs is het invullen van de gezondheidsverklaring bij het CBR noodzakelijk om het praktijkexamen te kunnen plannen.</p>
<br/><br/>
<p class="font-semibold">Meer informatie?</p>
<p> In verband met de privacywetgeving geeft het CBR ons geen informatie over de gezondheidsverklaring. Bij vragen kan er contact opgenomen worden met het CBR op 088-2277700.</p>
                    `,
                },

            ],
        },
    ],
}