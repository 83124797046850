export const Privacy = {
    hero: {
        picture: '',
        title: 'Privacyverklaring',
        text: null,
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: 'Privacybeleid Superdrive',
            subtitle: 'Versie 1.0 | Deze pagina is voor het laatst aangepast op 20-03-2024.',
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
  <p>Wij zijn er van bewust dat u vertrouwen stelt in ons. Wij zien het dan ook als onze verantwoordelijkheid om uw privacy te beschermen. Op deze pagina laten we u weten welke gegevens we verzamelen als u onze website gebruikt, waarom we deze gegevens verzamelen en hoe we hiermee uw gebruikservaring verbeteren. Zo snapt u precies hoe wij werken.</p>
  <br />
  <p>Dit privacybeleid is van toepassing op de diensten van Superdrive. U dient zich ervan bewust te zijn dat Superdrive niet verantwoordelijk is voor het privacybeleid van andere sites en bronnen. Door gebruik te maken van deze website geeft u aan het privacy beleid te accepteren. Superdrive respecteert de privacy van alle gebruikers van haar site en draagt er zorg voor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld.</p>
  <br />
  <p class="font-semibold">Ons gebruik van verzamelde gegevens</p class="font-semibold">
  <p>Gebruik van onze diensten</p>
  <p>Als u zich aanmeldt voor een van onze diensten vragen we u om persoonsgegevens te verstrekken. Deze gegevens worden gebruikt om de dienst uit te kunnen voeren. De gegevens worden opgeslagen op eigen beveiligde servers van Superdrive of die van een derde partij. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.</p>
  <br />
  <p class="font-semibold">Communicatie</p>
  <p>Wanneer u e-mail of andere berichten naar ons verzendt, is het mogelijk dat we die berichten bewaren. Soms vragen wij u naar uw persoonlijke gegevens die voor de desbetreffende situatie relevant zijn. Dit maakt het mogelijk uw vragen te verwerken en uw verzoeken te beantwoorden. De gegevens worden opgeslagen op eigen beveiligde servers van Superdrive of die van een derde partij. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.</p>
  <br />
  <p class="font-semibold">Doeleinden</p>
  <p>We verzamelen of gebruiken geen informatie voor andere doeleinden dan de doeleinden die worden beschreven in dit privacybeleid tenzij we van tevoren uw toestemming hiervoor hebben verkregen.</p>
  <br />
  <p class="font-semibold">Derden</p>
  <p>De informatie wordt niet met derden gedeeld. In enkele gevallen kan de informatie intern gedeeld worden. Onze werknemers zijn verplicht om de vertrouwelijkheid van uw gegevens te respecteren.</p>
  <br />
  <p class="font-semibold">Veranderingen</p>
  <p>Deze privacyverklaring is afgestemd op het gebruik van en de mogelijkheden op deze site. Eventuele aanpassingen en/of veranderingen van deze site, kunnen leiden tot wijzigingen in deze privacyverklaring. Het is daarom raadzaam om regelmatig deze privacyverklaring te raadplegen.</p>
  <br />
  <p class="font-semibold">Keuzes voor persoonsgegevens</p>
  <p>Wij bieden alle bezoekers de mogelijkheid tot het inzien, veranderen, of verwijderen van alle persoonlijke informatie die op dat moment aan ons is verstrekt.</p>
<br />
  <p>We controleren regelmatig of we aan dit privacybeleid voldoen. Als u vragen heeft over dit privacybeleid, kunt u contact met ons opnemen: <a href="mailto:contact@superdrive.nl">contact@superdrive.nl</a>, +31 6 12646163</p>
                    `,
                }
            ],
        },
    ],
}