<template>
    <section v-for="block in blocks" :key="block.id" :class="'block-' + block.id" class="container">
        <div class="" :class="posistions[block.posistion]">
            <h1 v-if="block.title" class="text-4xl tracking-tight font-extrabold text-gray-900 line-clamp-1">
                {{ block.title }}
            </h1>
            <p v-if="block.subtitle" class="mt-4 font-light md:text-lg line-clamp-3">
                {{ block.subtitle }}
            </p>
        </div>
        <div v-if="block.content" class="md:grid mt-4 gap-8 xl:gap-16" :class="block.cols ? `grid-cols-${block.cols}`: `grid-cols-2`">
            <div v-for="content in block.content" :key="content.id" :class="'col-' + content.type">
                <component :is="components[content.type]" :content="content" />
            </div>
        </div>
        <div class="" :class="posistions[block.posistion]">
            <a v-if="block.button" type="button" :href="block.button.link" class="button">
                {{ block.button.text }}
            </a>
        </div>
    </section>
</template>

<script setup>
import { defineProps } from 'vue';
import {
    TextBlock,
    ImageBlock,
    ColumnBlock,
    BannerBlock,
    FormBlock,
} from '@/components/blocks';

defineProps({
    blocks: {
        type: Object,
        required: true
    }
})

const components = {
    TextBlock,
    ImageBlock,
    ColumnBlock,
    BannerBlock,
    FormBlock,
}

const posistions = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
}
</script>