export const Tarieven = {
    hero: {
        picture: '',
        title: 'Tarieven',
        text: 'Lespakketen en tarieven voor rijlessen bij Super Drive',
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
                            <style scoped>
                            td {
                                border: 1px solid #dddddd;
                                padding: 8px;
                                width: 80%;
                            }
                            </style>
                            <table class="w-full">
                            <tr>
                                <td>Proef les (60 min)</td>
                                <td>€50,-</td>
                            </tr>
                            <tr>
                                <td>losse les (60 min)</td>
                                <td>€55,-</td>
                            </tr>
                            <tr>
                                <td>CBR Tussentijdsetoets</td>
                                <td>€200,-</td>
                            </tr>
                            <tr>
                                <td>CBR Praktijk Examen</td>
                                <td>€250,-</td>
                            </tr>
                            <tr>
                                <td>BNO Examen</td>
                                <td>€300,-</td>
                            </tr>
                            <tr>
                                <td>Faalangst Examen</td>
                                <td>€350,-</td>
                            </tr>
                            </table><br/><br/>
                            <p class="font-semibold">lespakketen</p><br/><br/>
                            <table class="w-full">
                            <tr>
                                <td>Pakket A 20 lessen incl examen</td>
                                <td>€1350,-</td>
                            </tr>
                            <tr>
                                <td>Pakket B 25 lessen incl examen</td>
                                <td>€1625,-</td>
                            </tr>
                            <tr>
                                <td>Pakket C 30 lessen incl examen</td>
                                <td>€1900,-</td>
                            </tr>
                            <tr>
                                <td>Pakket D 35 lessen incl examen</td>
                                <td>€2175,-</td>
                            </tr>
                            <tr>
                                <td>Pakket E 40 lessen incl examen</td>
                                <td>€2450,-</td>
                            </tr>
                            </table><br/><br/>
                            <p class="font-semibold">Pakketen met Tussentijdse toets en Examen</p><br/><br/>
                            <table class="w-full">
                            <tr>
                                <td>Pakket F 25 lessen incl tussentijdse toets incl examen</td>
                                <td>€1825,-</td>
                            </tr>
                            <tr>
                                <td>Pakket G 30 lessen incl tussentijdse toets incl examen</td>
                                <td>€2100,-</td>
                            </tr>
                            <tr>
                                <td>Pakket H 35 lessen incl tussentijdse toets incl examen</td>
                                <td>€2375,-</td>
                            </tr>
                            <tr>
                                <td>Pakket I 40 lessen incl tussentijdse toets incl examen</td>
                                <td>€2650,-</td>
                            </tr>
                            </table><br/><br/>
                            <p class="font-semibold">Bijzondere pakketten</p><br/><br/>
                            <table class="w-full">
                            <tr>
                                <td>Herexamen pakket 4 lessen van ( 60 min ) + Herexamen</td>
                                <td>€470,-</td>
                            </tr>
                            <tr>
                                <td>iTheorie - Een compleet online theoriepakket</td>
                                <td>€25,-</td>
                            </tr>
                            </table>
                    `,
                    button: {
                        text: 'Aanmelden',
                        link: '/contact',
                    }
                },
            ],
        },
    ],
}