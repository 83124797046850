<template>
    <HeroBlock :hero="pageContent.hero" />
    <MainBlock :blocks="pageContent.blocks" />
</template>

<script setup>
import HeroBlock from '@/components/HeroBlock.vue';
import MainBlock from '@/components/MainBlock.vue';
import { useMenuStore } from '@/stores/menu';
import { pages} from '@/data.js';
import { defineProps } from 'vue';

const props = defineProps({
    page: String,
})
const store = useMenuStore();
const pageContent = pages[props.page] ? pages[props.page] : pages['notfound'];
store.setActivePage(props.page);
</script>