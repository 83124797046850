import { createRouter, createWebHistory } from 'vue-router'

import PageView from '../views/PageView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageView,
    props: { page: 'home' }
  },
  {
    path: '/:page',
    name: 'app',
    component: PageView,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
