import { Home } from './dataPages/home.js'
import { Contact } from './dataPages/contact.js'
import { NotFound }  from './dataPages/notfound.js'
import { Opleiding } from './dataPages/opleiding.js'
import { Tarieven } from './dataPages/tarieven.js'
import { Overons } from './dataPages/overons.js'
import { Proefles } from './dataPages/proefles.js'
import { Examen } from './dataPages/examen.js'
import { Gezondheidsverklaring } from './dataPages/gezondheidsverklaaring.js'
import { Voorwaarden } from './dataPages/voorwaarden.js'
import { Privacy } from './dataPages/privacy.js'

export const menu = [
    {
        title: 'Home',
        link: '/',
    },
    {
        title: 'Opleidingen',
        link: '/opleidingen',
    },
    {
        title: 'Tarieven',
        link: '/tarieven',
    },
    {
        title: 'Over ons',
        link: '/overons',
    },
    {
        title: 'Contact',
        link: '/contact',
    },
]

export const pages = {
    home: Home,
    contact: Contact,
    opleidingen: Opleiding,
    tarieven: Tarieven,
    overons: Overons,
    proefles: Proefles,
    voorwaarden: Voorwaarden,
    examen: Examen,
    gezondheidsverklaring: Gezondheidsverklaring,
    notfound: NotFound,
    privacyverklaring: Privacy,
}

export const footer = [
    {
        title: 'Examen',
        link: '/examen',
    },
    {
        title: 'Algemeene voorwaarden',
        link: '/voorwaarden',
    },
    {
        title: 'Gezondheidsverklaring',
        link: '/gezondheidsverklaring',
    },
    {
        title: 'Privacyverklaring',
        link: '/privacyverklaring',
    },
]