export const Examen = {
    hero: {
        picture: '',
        title: 'Examen doen',
        text: null,
        button: {
            text: null,
            link: null
        }
    },
    blocks: [
        {
            id: 1,
            posistion: 'left',
            title: null,
            subtitle: null,
            button: null,
            cols: 1,
            content: [
                {
                    id: 1,
                    type: 'TextBlock',
                    title: null,
                    subtitle: null,
                    text: `
<p class="font-semibold">Vooraf</p>
<p>In het examencentrum maakt u eerst kennis met de examinator. Deze legt uit hoe het examen verloopt. Na controle van uw identiteitsbewijs en uw theoriecertificaat overhandigt u het gesloten formulier zelfreflectie. Op dat formulier heeft u vóór het examen uw sterke en minder sterke punten in het verkeer gezet. Dit formulier wordt na de examenuitslag met u besproken. Daarna volgt op de parkeerplaats een ogentest, waarbij u het kenteken van een stilstaande auto moet kunnen lezen op een afstand van ongeveer 25 meter. Vervolgens vraagt de examinator u een aantal voorbereidings- en controlehandelingen uit te voeren aan de examenauto.</p>
<br/><br/>
<p class="font-semibold">De rit</p>
<p>Dan begint de rit. De examinator let onder meer op uw beheersing van de auto, kijkgedrag, voorrang verlenen en het rekening houden met andere weggebruikers. Hij beoordeelt u op zeven examenonderdelen, zoals het in- en uitvoegen, het gedrag bij kruispunten en de bijzondere manoeuvres. In het vernieuwde rijexamen rijdt u een gedeelte van het examen –zo’n tien tot vijftien minuten– zelfstandig naar een bepaalde bestemming. Als u voor dit praktijkexamen tijdens de tussentijdse toets een vrijstelling hebt verdiend voor de bijzondere manoeuvres, dan wordt dit onderdeel overgeslagen. (De vrijstelling geldt altijd alleen voor het eerstvolgende examen na uw tussentijdse toets).</p>
<br/><br/>
<p class="font-semibold">Fouten</p>
<p>U krijgt tijdens het examen alle gelegenheid te laten zien wat u kunt. Helemaal foutloos hoeft niet, het gaat om het totaalbeeld. Belangrijk is hoe u reageert op het overige verkeer en of u de situatie meester bent. Kortom, de examinator bekijkt of u voldoende in huis hebt om veilig en zelfstandig aan het verkeer deel te nemen.</p>
<br/><br/>
<p class="font-semibold">Geslaagd</p>
<p>Direct na afloop vertelt de examinator in het examencentrum de uitslag. Als u bent geslaagd, bespreekt de examinator het zelfreflectieformulier met u en wordt u Verklaring van rijvaardigheid en de Verklaring van geschiktheid geregistreerd in het Centraal Rijbewijzen Register (CRB). De gemeenten en het CBR kunnen dit register raadplegen om vast te stellen of u bent geslaagd voor het examen. De registratie van de Verklaring van rijvaardigheid is drie jaar geldig, de registratie van de Verklaring van geschiktheid een jaar.</p>
<br/><br/>
<p class="font-semibold">Het rijbewijs ophalen</p>
<p>Bij het gemeentehuis in uw woonplaats kunt u, tegen overlegging van een pasfoto, legitimatie en het vereiste bedrag, uw rijbewijs aanvragen. Om bij de gemeente voor het rijbewijs in aanmerking te komen, moet u op het moment van aanvraag in Nederland wonen en in het jaar daarvóór minstens 185 dagen in Nederland hebben gewoond.</p>
<br/><br/>
<p class="font-semibold">Gezakt</p>
<p>Wanneer u bent gezakt, licht de examinator toe welke onderdelen onvoldoende waren. Het uitslagformulier met deze punten kan uw instructeur achteraf voor u uitdraaien. Het is verstandig deze punten te bespreken met uw instructeur in verband met vervolglessen. Daarna bespreekt de examinator het formulier zelfreflectie met u. Wanneer uw antwoorden op het zelfreflectieformulier verschillen van de bevindingen van de examinator, is het verstandig dit ook met uw instructeur te bespreken.</p>
<br/><br/>
<p class="font-semibold">Vier keer gezakt?</p>
<p>Wanneer u voor de vierde keer binnen vijf jaar bent gezakt komt u in aanmerking voor een nader onderzoek. Vanaf 1 november 2017 moet elke examenkandidaat zelf een gezondheidsverklaring indienen bij het CBR. Zonder deze verklaring kan het examen niet aangevraagd worden. Vul je gezondheidsverklaring al aan het begin van je rijopleiding in, zeker als je twijfels hebt over je gezondheid. Op die manier voorkom je dat je moet wachten voor je op examen kunt!</p>
<br/><br/>
<p class="font-semibold">Gezondheidsverklaring voor rijexamen zelf invullen</p>
<p>Eigen Verklaring wordt Gezondheidsverklaring. Voor het inplannen van een praktijkexamen is het noodzakelijk dat de kandidaat medisch goedgekeurd is door het CBR. Vanaf 1 november 2017 is het voor rijscholen niet langer mogelijk om een Eigen verklaring in te dienen voor de kandidaten bij het CBR. Dit heeft het CBR besloten, omdat iedereen zélf verantwoordelijk is voor zijn gezondheidsgegevens. Door zelf de Gezondheidsverklaring CBR in te vullen, denkt een kandidaat bewust na over de vragen over zijn gezondheid. Bovendien zijn deze gegevens persoonlijk en zeer vertrouwelijk.</p>
<br/><br/>
<p class="font-semibold">Waarom een medische verklaring?</p>
<p>Als bestuurder ben je verantwoordelijk voor je eigen veiligheid. En voor die van anderen. Om te mogen rijden moet je daarom volgens de wet ‘rijgeschikt’ zijn. Dat wil zeggen: geestelijk en lichamelijk in staat om te rijden. Heb je bijvoorbeeld gezondheidsproblemen of een beperking? Of gebruik je medicijnen die invloed hebben op hoe je rijdt? Dan mag je niet altijd de weg op.Om je rijgeschiktheid te kunnen beoordelen,stelt het CBR je een aantal vragen over je gezondheid. Deze vragen staan op het formulier Eigen verklaring (Gezondheidsverklaring). Je koopt een Eigen verklaring op Mijn CBR.</p>
<br/><br/>
<p class="font-semibold">Zonder deze verklaring kan het praktijkexamen niet aangevraagd worden</p>
<p>Om geen vertraging in het reserveringsproces op te lopen, is het belangrijk dat de gezondheidsverklaring aan het begin van je rijopleiding wordt ingediend! De kosten van deze gezondheidsverklaring zijn in 2024 44,50.</p>
<br/><br/>
<p class="font-semibold">Voor welke rijbewijzen is dit van toepassing?</p>
<p>Bij de bromfiets en brommobiel (AM-rijbewijs) is het invullen van de gezondheidsverklaring niet nodig. Voor het auto-, motor-, aanhanger achter de auto- en het trekker rijbewijs is het invullen van de gezondheidsverklaring bij het CBR noodzakelijk om het praktijkexamen te kunnen plannen</p>
<br/><br/>
<p class="font-semibold">Meer informatie?</p>
<p>In verband met de privacywetgeving geeft het CBR ons geen informatie over de gezondheidsverklaring. Bij vragen kan er contact opgenomen worden met het CBR 088-2277700. De proef Begeleid Rijden (2todrive) is definitief door de Tweede Kamer aangenomen. De startdatum van de proef 2toDrive is November 2011. Omdat er een hoop misverstanden zijn wie er aan de proef 2todrive mee mogen doen, volgt nu een duidelijke uitleg. Bent u geboren na 01-11-1994 U mag praktijklessen gaan volgen vanaf 16½ jaar. U mag praktijkexamen doen vanaf 17 jaar. U mag theorie examen doen vanaf 16 jaar. De geldigheid van het theoriecertificaat wordt verlengd van 1.5 jaar. Na het behalen van het rijbewijs voor uw achttiende mag u alleen onder begeleiding rijden. U moet niet alleen bij de gemeente een rijbewijs aanvragen. Ook moet u via www.2todrive.nl een begeleiderspas aanvragen. Op deze pas staan dan de namen van de begeleiders, deze moeten minimaal 27 jaar zijn. En minimaal 5 jaar in het bezit zijn van een geldig rijbewijs. U mag tot uw achttiende nu alleen maar rijden met uw behaalde rijbewijs, uw begeleiderspas, en een begeleider die op uw begeleiderspas staat. Na uw achttiende vervalt deze regeling en heeft u een normaal rijbewijs. Bent u geboren voor 01-11-1994 U komt gedeeltelijk in aanmerking voor de proef (2todrive). U mag wel alvast op uw zeventiende met rijlessen beginnen. U mag wel al theorie examen doen voor uw achttiende. Het theoriecertificaat heeft ook voor u een geldigheid van 1.5 jaar. U mag pas praktijkexamen doen vanaf uw achttiende.</p>
<br/><br/>
<p class="font-semibold">Machtig jouw autorijschool met DigiD bij mijn <a href="https://www.cbr.nl/nl" class="underline text-blue-500">CBR</a></p>
<p>Voordat de rijschool het praktijkexamen voor jou gaat aanvragen moet jij daar eerst toestemming voor geven. Dat doe je met een machtiging. Voordeel hiervan is dat je direct een e-mail ontvangt als de rijschool een praktijkexamen of toets voor je heeft gereserveerd of als deze is verschoven. Daarnaast zijn jouw adresgegevens automatisch voor jouw rijschool beschikbaar in het reserveringssysteem van het CBR.</p>

                    `,
                }
            ],
        },
    ],
}